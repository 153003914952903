import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/home/front/auto-desk/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.3.1_react@18.3.1/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.3.1_react@18.3.1/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/swiper@11.1.9/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/contexts/ModalFastContact.jsx");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/style/index.scss");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/react-phone-input-2@2.15.1_react-dom@18.3.1_react@18.3.1/node_modules/react-phone-input-2/lib/style.css");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/swiper@11.1.9/node_modules/swiper/modules/navigation.css");
